<template>
  <div>
    <!-- banner -->
    <div class="second_banner">
      <img src="../images/detail-banner.jpg" alt="新闻动态" />

      <div class="div_beard">
        <p>
          <a v-for="(bread,index) in breads" :key="index">{{bread.name}}</a>
        </p>
      </div>
    </div>

    <div class="second_nav">
      <ul>
        <li
          :class="{
            'on': activeTabId === tab.id
          }"
          v-for="(tab,index) in tabs" :key="index"
        >
          <a @click="$router.push(tab.path)">
          <span>{{tab.name}}</span>
          </a>
        </li>
      </ul>
    </div>

    <slot />

  </div>
</template>

<script>

export default {
  props: {
    activeTabId: { type: String, default: '' },
    breads: { type: Array, default: () => [] },
    tabs: { type: Array, default: () => [] },
  }
}
</script>

<style lang="less" scoped>
@import "../style/common.css";
@import "../style/global.css";
@import "../style/news.css";

/deep/.el-pagination.is-background .el-pager li:not(.disabled):hover {
  color: #b82e1b !important;
}
/deep/.el-pagination {
  margin-top: 1rem;
  .btn-next,
  .btn-prev {
    background: transparent;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2.6rem;
    height: 0.6rem;
    line-height: 0.6rem;
    text-align: center;
    border: 1px solid #eaeaea;
    font-size: 0.18rem;
    border-radius: 5px;
    &:not(.disabled):hover {
      background: #b82e1b;
      color: #fff;
    }
  }
  .el-pager {
    &li:not(.disabled):hover {
      color: #b82e1b !important;
    }
    .more {
      background: transparent;
    }
    .number {
      border-radius: 5px;
      background: transparent;
      :hover {
        color: #b82e1b !important;
      }
      &.active {
        background-color: #b82e1b !important;
      }
    }
  }
}
</style>

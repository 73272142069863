<!-- 详情 -->
<template>
  <tab-page-layout
    :activeCode="activeCode"
  >
    <div class="container newsDetails">
      <div class="div_model">
        <div class="details_tit">
          <h3>{{ info.title }}</h3>
          <p>
            <span>发布日期：{{ info.ctime }}</span>
            <span
              >阅读(<i id="hits">{{ info.count }}</i
              >)</span
            >
          </p>
        </div>

        <div class="details_main" v-html="info.content"></div>
      </div>
    </div>
  </tab-page-layout>
</template>

<script>
import portalApi from '../../../lvya-common/src/api/eos/portal'
import DetailLayout from './components/DetailLayout.vue'
import TabPageLayout from './components/TabPageLayout.vue'
import { useAddArticleCount } from './utils/useAddArticleCount'

export default {
  props: {},
  components: { DetailLayout, TabPageLayout },
  data() {
    return {
      portalSchoolInfo: '',
      lmActive: this.$route.query.id ? this.$route.query.id : '',
      lmName: this.$route.query.title ? this.$route.query.title : '',
      columnList: [],
      info: '',
    }
  },
  computed: {
    activeCode() {
      return this.$route.query.activeCode || ''
    }
  },
  watch: {
    async $route(to, from) {
      this.lmActive = this.$route.query.id
      this.lmName = this.$route.query.title
      // await this.getLm()
      this.getNews()
      console.log(this.lmActive)
    },
  },
  methods: {
    // 文章详情
    async getNews() {
      let resData = await portalApi.getArtsInfo({
        id: this.$route.query.id,
      })
      resData.content = this.escape2Html(resData.content)
      this.info = resData
    },
    escape2Html(str) {
      const arrEntities = {
        lt: '<',
        gt: '>',
        nbsp: '\u00a0',
        amp: '&',
        quot: '"',
      }
      return str.replace(/&(lt|gt|nbsp|amp|quot);/gi, function(all, t) {
        return arrEntities[t]
      })
    },
  },
  async created() {
    this.portalSchoolInfo = JSON.parse(
      localStorage.getItem('portalSchoolInfo')
    )
    useAddArticleCount(this.$route.query.id)
    this.getNews()
  },
  mounted() {},
}
</script>

<style lang="less" scoped>
/* @import url(); 引入css类 */
.redDetail_div {
  width: 100%;
  min-height: 100vh;
  .list_bg {
    width: 100%;
    height: 550px;
    background: url("../../../../public/static/ershi/listbg.png") no-repeat;
    background-size: 100% 100%;
  }
  .list_div {
    width: 1200px;
    min-height: 700px;
    margin: 0 auto;
    background: #fff;
    margin-top: -320px;
    border-radius: 6px;
    display: flex;
    padding-bottom: 30px;
    box-sizing: border-box;
    .left {
      width: 200px;
      min-height: 100px;
      line-height: 0;
      background: #eee;
      .img {
        max-width: 100%;
      }
      .left_ul {
        min-height: 500px;
        background: #eee;
        margin-block-start: 0em;
        margin-block-end: 0em;
        padding-inline-start: 0px;
        li {
          height: 50px;
          line-height: 50px;
          background: #eee;
          text-align: center;
          color: #333;
          font-size: 14px;
          font-weight: 500;
          cursor: pointer;
        }
        .column {
          background: #170cfc;
          color: #fff;
        }
      }
    }
    .right {
      width: calc(100% - 200px);
      flex: 1;
      padding: 15px;
      box-sizing: border-box;
      .top {
        height: 40px;
        border-bottom: 1px solid #eee;
        line-height: 40px;
        .title {
          display: flex;
          .red {
            width: 4px;
            height: 35px;
            background: #0866fc;
            display: inline-block;
          }
          .text {
            color: #0866fc;
            display: inline-block;
            padding-left: 10px;
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
      .content {
        padding: 15px;
        box-sizing: border-box;
        .title {
          font-size: 22px;
          text-align: center;
        }
        .time {
          text-align: center;
          font-size: 14px;
          color: #888;
          margin-top: 5px;
          span:nth-child(2) {
            padding-left: 20px;
          }
        }
        .details-text {
          color: #333;
          font-size: 14px;
          line-height: 33px;
          padding-bottom: 30px;
          margin-top: 10px;
          /deep/ img {
            width: auto;
            height: auto;
            max-width: 100%;
          }
        }
      }
    }
  }
}
</style>
